.chip {
    @apply bg-white/10 rounded-full w-28 py-0.5 mt-4 mx-2 transition-all ease-in-out
}

.chip-text {
    @apply text-white text-lg font-black opacity-50 text-center
}

.chip.green {
    @apply bg-green/50 text-green
}

.chip-text.green {
    @apply text-green opacity-100
}

.chip.yellow {
    @apply bg-yellow/50 text-yellow
}

.chip-text.yellow {
    @apply text-yellow opacity-100
}

.chip.red {
    @apply bg-red/50 text-red
}

.chip-text.red {
    @apply text-red opacity-100
}

.chip.blue {
    @apply bg-blue/50 text-blue
}

.chip-text.blue {
    @apply text-blue opacity-100
}
