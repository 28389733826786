.App {
  display: flex;
  flex-direction: column;
  height: 100% ;
  position: absolute;
  width: 100% ;
  /*overflow: hidden;*/
}
.nav {
  z-index: 10;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
}

.nav-enter {
  position: absolute;
  transform: translateY(-110%);
}
.nav-enter-active {
  transform: translateX(0%);
  transition: all 500ms ease;
}
.nav-exit {
  position: absolute;
}
.nav-exit-active {
  transform: translateY(-110%);
  transition: all 500ms ease;
}

.active {
  @apply opacity-100
}
