.carousel {
    overflow: hidden;
    width: 100%;
}

.inner {
    white-space: nowrap;
    transition: transform 0.3s;
}

.carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    /*height: 200px;*/
    color: #fff;
}

.indicators {
    display: flex;
    justify-content: center;
}

.indicators > div {
    @apply bg-white rounded-full w-2 h-2 opacity-50 m-1 mt-8
}

.indicators > div.active {
    @apply opacity-100
}
